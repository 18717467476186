import { ChangeEvent, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Avatar,
  Button,
  Container,
  Grid,
  MenuItem,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import clsx from 'clsx';

import TripDTO from '#root/models/TripDTO';
import { GuveryTextField, Notification } from '#root/components';
import { GuveryPageHeader, GuverySpace } from '#root/ui-components';
import { GuveryPaper } from '#root/ui-components/cards';
import { useNotification, useResponsive } from '#root/hooks';
import { tripService } from '#root/services';
import * as ROUTES from '#root/constants/navigation';
import styles from './travelerTripNew.module.scss';

const housingArray = ['Casa propia/familiar', 'Hotel', 'AirBnb', 'No tengo un lugar fijo'];

const initialNewTrip: TripDTO = {
  accommodation_type: '',
  adults_qty: 1,
  city: '',
  destination_country: 'Perú',
  // departure_date: null,
  max_reception_date: null,
  arrive_date: null,
  fecha_entrega: null,
  flag_no_travel: false,
  flag_receive: true,
  flag_pickup: false,
  lugar_entrega: '',
  nombre: '',
  departure_country: 'Estados Unidos',
  zipcode: '',
};

const TravelerTripNew = () => {
  const history = useHistory();
  const { notification, createMessage, closeMessage } = useNotification();
  const { isMobile } = useResponsive();
  const [tripObj, setTripObj] = useState<TripDTO>(initialNewTrip);
  const [btnDisable, setBtnDisable] = useState(true);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTripObj({
      ...tripObj,
      [e.target.id]: e.target.value,
    });
  };

  const handleSelectChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTripObj({
      ...tripObj,
      accommodation_type: e.target.value,
    });
  };

  const handleSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTripObj({
      ...tripObj,
      [e.target.name]: e.target.checked,
    });
  };

  const handleDateChange = (name: string) => (e: any) => {
    if (name === 'arrive_date') {
      const date1 = new Date(e);
      const receptionDate = date1.setDate(date1.getDate() - 2);
      const date2 = new Date(e);
      const deliveryDate = date2.setDate(date2.getDate() + 1);
      
      setTripObj((prevTripObj) => ({
        ...prevTripObj,
        [name]: e,
        max_reception_date: prevTripObj.max_reception_date || receptionDate,
        fecha_entrega: prevTripObj.fecha_entrega || deliveryDate,
      }));
      return;
    }
    if (name === 'fecha_entrega') {
      const date1 = new Date(e);
      const receptionDate = date1.setDate(date1.getDate() - 3);
      const date2 = new Date(e);
      const arriveDate = date2.setDate(date2.getDate() - 1);

      setTripObj((prevTripObj) => ({
        ...prevTripObj,
        [name]: e,
        max_reception_date: prevTripObj.max_reception_date || receptionDate,
        arrive_date: prevTripObj.arrive_date || arriveDate,
      }));
      return;
    }

    setTripObj({
      ...tripObj,
      [name]: e,
    });
  };

  const validateTripObj = () => {
    for (var i in tripObj) {
      if (tripObj[i] === '' || tripObj[i] === undefined) {
        setBtnDisable(true);
        return;
      }
    }
    setBtnDisable(false);
  };

  const handleCreateTrip = async () => {
    const {
      nombre,
      departure_country,
      destination_country,
      city,
      zipcode,
      accommodation_type,
      lugar_entrega,
      adults_qty,
      arrive_date,
      max_reception_date,
      fecha_entrega,
      flag_receive,
      flag_pickup,
      flag_no_travel,
    } = tripObj;

    const arg: TripDTO = {
      nombre: nombre,
      departure_country: departure_country,
      destination_country: destination_country,
      city: city,
      zipcode: zipcode,
      accommodation_type: accommodation_type,
      lugar_entrega: lugar_entrega,
      adults_qty: adults_qty,
      arrive_date: format(new Date(arrive_date), 'yyyy-MM-dd'),
      max_reception_date: format(new Date(max_reception_date), 'yyyy-MM-dd'),
      fecha_entrega: format(new Date(fecha_entrega), 'yyyy-MM-dd'),
      flag_receive: flag_receive ? '1' : '0',
      flag_pickup: flag_pickup ? '1' : '0',
      flag_no_travel: flag_no_travel ? '1' : '0',
    };
    fetchCreateTrip(arg);
  };

  const fetchCreateTrip = async (arg: TripDTO) => {
    createMessage.info('...Enviando datos');
    const res = await tripService.create(arg);
    if (res.info.error) {
      createMessage.error(res.info.message);
      return;
    }
    createMessage.success(res.info.message);
    history.push(ROUTES.TRAVELER_TRIPS);
  };

  useEffect(() => {
    validateTripObj();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripObj]);

  useEffect(() => {
    if (tripObj.flag_receive === false) {
      setTripObj({
        ...tripObj,
        flag_pickup: true,
      });
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripObj.flag_receive]);

  useEffect(() => {
    if (tripObj.flag_pickup === false) {
      setTripObj({
        ...tripObj,
        flag_receive: true,
      });
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripObj.flag_pickup]);

  return (
    <div className={clsx(styles.root, 'guv-main-container')}>
      <Container maxWidth="lg">
        <GuveryPageHeader
          title="Nuevo Viaje"
          subTitle="Completa los datos de tu estancia en EE.UU. (origen) y tu viaje hacia Perú (destino)."
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <GuverySpace direction="vertical" size="large">
              <GuveryPaper disableHover disableElevation className={styles.card}>
                <div className={styles.header}>
                  <div className={styles.headerContent}>
                    <GuverySpace>
                      <span className={styles.headerTitle}>Origen:</span>
                      <span className={styles.headerCountry}>
                        <Avatar
                          className={styles.flag}
                          alt="United States"
                          src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
                        />
                        <span className={styles.name}>{tripObj.departure_country}</span>
                      </span>
                    </GuverySpace>
                  </div>
                </div>
                <hr className={styles.divider} />
                <div className={styles.body}>
                  <GuverySpace direction="vertical" size="large">
                    <GuverySpace wrap={isMobile} align="start" size="large">
                      <GuveryTextField
                        id="city"
                        label="Ciudad en EE.UU."
                        value={tripObj.city}
                        onChange={handleInputChange}
                      />
                      <GuveryTextField
                        id="zipcode"
                        label="Zipcode"
                        value={tripObj.zipcode}
                        onChange={handleInputChange}
                        error={tripObj.zipcode.toString().length > 5 ? true : false}
                        helperText={
                          tripObj.zipcode.toString().length > 5 && '*Maximo 5 digitos'
                        }
                      />
                    </GuverySpace>
                    <GuveryTextField
                      select
                      label="Alojamiento en EE.UU"
                      id="accommodation_type"
                      value={tripObj.accommodation_type}
                      onChange={handleSelectChange}>
                      <MenuItem value="" disabled>
                        Alojamiento en EE.UU.
                      </MenuItem>
                      {housingArray.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </GuveryTextField>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <div>
                          <FormControlLabel
                            label="Puedo recibir compras."
                            control={
                              <Switch
                                color="primary"
                                name="flag_receive"
                                checked={Boolean(tripObj.flag_receive)}
                                onChange={handleSwitchChange}
                              />
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div>
                          <FormControlLabel
                            label="Puedo recoger en tienda."
                            control={
                              <Switch
                                color="primary"
                                name="flag_pickup"
                                checked={Boolean(tripObj.flag_pickup)}
                                onChange={handleSwitchChange}
                              />
                            }
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </GuverySpace>
                </div>
              </GuveryPaper>
              <GuveryPaper disableHover disableElevation className={styles.card}>
                <div className={styles.header}>
                  <div className={styles.headerContent}>
                    <GuverySpace>
                      <span className={styles.headerTitle}>Destino:</span>
                      <span className={styles.headerCountry}>
                        <Avatar
                          className={styles.flag}
                          alt="United States"
                          src="https://upload.wikimedia.org/wikipedia/commons/c/cf/Flag_of_Peru.svg"
                        />
                        <span className={styles.name}>{tripObj.destination_country}</span>
                      </span>
                    </GuverySpace>
                  </div>
                </div>
                <hr className={styles.divider} />
                <div className={styles.body}>
                  <GuverySpace direction="vertical" size="large">
                    <GuverySpace wrap={isMobile} size="large">
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                        <KeyboardDatePicker
                          disableToolbar
                          fullWidth
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          label="Fecha de viaje"
                          value={tripObj.arrive_date}
                          onChange={handleDateChange('arrive_date')}
                          minDate={new Date()}
                          InputAdornmentProps={{ position: 'start' }}
                          minDateMessage="La fecha no debe ser anterior a hoy"
                        />
                      </MuiPickersUtilsProvider>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                        <KeyboardDatePicker
                          disableToolbar
                          fullWidth
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          label="Fecha de entrega"
                          value={tripObj.fecha_entrega}
                          onChange={handleDateChange('fecha_entrega')}
                          minDate={tripObj.arrive_date}
                          InputAdornmentProps={{ position: 'start' }}
                          minDateMessage="La fecha no debe ser anterior a la fecha de viaje"
                        />
                      </MuiPickersUtilsProvider>
                    </GuverySpace>
                    <GuveryTextField
                      id="lugar_entrega"
                      label="Distrito(s) de entrega (Lima)"
                      value={tripObj.lugar_entrega}
                      onChange={handleInputChange}
                    />
                  </GuverySpace>
                </div>
              </GuveryPaper>
            </GuverySpace>
          </Grid>
          <Grid item xs={12} md={4}>
            <GuverySpace direction="vertical" size="large">
              <GuveryPaper disableHover disableElevation className={styles.card}>
                <div className={styles.header}>
                  <div className={styles.headerContent}>
                    <span className={styles.headerTitle}>Detalles</span>
                  </div>
                </div>
                <hr className={styles.divider} />
                <div className={styles.body}>
                  <GuverySpace direction="vertical" size="large">
                    <GuveryTextField
                      id="nombre"
                      label="Nombre del viaje"
                      value={tripObj.nombre}
                      onChange={handleInputChange}
                    />
                    <GuveryTextField
                      id="adults_qty"
                      label="Cantidad de adultos"
                      type="number"
                      value={tripObj.adults_qty}
                      onChange={handleInputChange}
                    />
                    <div>
                      <FormControlLabel
                        label="Yo no estoy viajando, pero soy él/la responsable."
                        control={
                          <Switch
                            color="primary"
                            name="flag_no_travel"
                            checked={Boolean(tripObj.flag_no_travel)}
                            onChange={handleSwitchChange}
                          />
                        }
                      />
                    </div>
                  </GuverySpace>
                </div>
              </GuveryPaper>
              <Button
                disableElevation
                fullWidth
                disabled={btnDisable}
                variant="contained"
                color="primary"
                onClick={handleCreateTrip}>
                Crear Viaje
              </Button>
            </GuverySpace>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default TravelerTripNew;
