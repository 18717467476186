import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { travelerService } from '#root/services';
import { useAuth, useGlobalNotification } from '#root/hooks';
import { GuveryLayout, GuveryPageHeader } from '#root/ui-components';
import { GuveryPaper } from '#root/ui-components/cards';
import { PackageIcon, PlusIcon, MoneyBagIcon } from '#root/icons';
import {
  TRAVELER_EARNINGS_REQUESTED,
  TRAVELER_EARNINGS,
  TRAVELER_ORDERS,
} from '#root/constants/navigation';

import Notes from './Notes';
import './travelerDashboard.scss';
import DashboardSummary from './ScoreSummary';
import ScoreCriteria from './ScoreCriteria';

const BoxInfoSmall = (props) => {
  const { color, icon, title, count, footer } = props;
  return (
    <GuveryPaper disableElevation>
      <div className="dashboard-box box-info-small">
        <div className={`info-small-icon ${color}`}>{icon}</div>
        <h5 className="info-small-title">{title}</h5>
        <h3 className="info-small-count">{count}</h3>
        <div className="info-small-content">{footer}</div>
      </div>
    </GuveryPaper>
  );
};

const BoxEarnings = (props) => {
  const { type, icon, title, time, count, footer } = props;
  return (
    <GuveryPaper disableElevation>
      <div className={`dashboard-box boxEarnings info-${type}`}>
        <div className="info-medium-icon">{icon}</div>
        <h4 className="info-medium-title">{title}</h4>
        <h3 className="info-medium-count">{count}</h3>
      </div>
    </GuveryPaper>
  );
};

function TravelerDashboard() {
  const { usuario } = useAuth();
  const globalNotification = useGlobalNotification();
  const [summary, setSummary] = useState(null);
  const [loading, setLoading] = useState(true);

  const earningsId = summary?.current_earnings?.id;
  const earningsPath = earningsId ? TRAVELER_EARNINGS_REQUESTED : TRAVELER_EARNINGS;

  // const hasRanking = summary?.traveler_ranking;

  const fetchTravelerSummary = async () => {
    setLoading(true);
    const res = await travelerService.dashboard();
    if (!res.error) {
      setSummary(res.data);
    } else {
      globalNotification.alert(res.message, 'error');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTravelerSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GuveryLayout className="traveler-dashboard">
      <GuveryPageHeader title={`Hola ${usuario.one_firstname} 👋`} />
      <Grid container spacing={3}>
        {/* Summary */}
        {loading ? (
          <Grid item xs={12}>
            <GuveryPaper disableElevation disableHover>
              <Skeleton variant="rect" height={130} />
            </GuveryPaper>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <DashboardSummary travelerScore={summary?.traveler_score} />
          </Grid>
        )}
        {/* Details */}
        {loading ? (
          <>
            <Grid item xs={12}>
              <GuveryPaper disableElevation disableHover>
                <Skeleton variant="rect" height={80} />
              </GuveryPaper>
            </Grid>
            {Array.from(Array(12).keys()).map((key, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <GuveryPaper disableElevation disableHover>
                  <Skeleton variant="rect" height={80} />
                </GuveryPaper>
              </Grid>
            ))}
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <h2>Tu desempeño</h2>
              <p style={{ marginTop: 6, fontSize: 16, color: 'var(--color-text-alt-3)' }}>
                Tu score es la sumatoria de puntos obtenidos en cada uno de los 6 criterios que
                medimos. Algunos criterios pueden restar puntos.
              </p>
            </Grid>
            {summary?.traveler_score?.current_score_criterias.map((criteria, index) => (
              <Grid item xs={12} key={index}>
                <ScoreCriteria criteria={criteria} />
              </Grid>
            ))}
          </>
        )}
        <Grid item xs={12}>
          <hr className="divider" />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          {!loading ? (
            <RouterLink to={earningsPath}>
              <BoxEarnings
                icon={<MoneyBagIcon />}
                title={'Mis ganancias por comisiones'}
                count={`$ ${
                  summary?.delivered_traveler_fee_sum
                    ? summary?.delivered_traveler_fee_sum
                    : '0.00'
                }`}
              />
            </RouterLink>
          ) : (
            <GuveryPaper disableElevation disableHover>
              <Skeleton variant="rect" height={145} />
            </GuveryPaper>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {!loading ? (
            <RouterLink to={TRAVELER_ORDERS}>
              <BoxInfoSmall
                icon={<PackageIcon className="pack-icon" />}
                title="Pedidos disponibles"
                count={summary.total_orders_qty}
                footer={
                  <>
                    <PlusIcon className="guv-text-success" />
                    <span className="guv-text-success guv-semibold">
                      {summary.last_week_orders_qty}
                    </span>
                    <span>Pedidos nuevos</span>
                  </>
                }
              />
            </RouterLink>
          ) : (
            <GuveryPaper disableElevation disableHover>
              <Skeleton variant="rect" height={145} />
            </GuveryPaper>
          )}
        </Grid>
        {/* Notes */}
        <Grid item xs={12}>
          <Notes notes={summary?.traveler_notes} loading={loading} />
        </Grid>
      </Grid>
    </GuveryLayout>
  );
}

export default TravelerDashboard;
