import { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Tooltip,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Skeleton } from '@material-ui/lab';
import DateFnsUtils from '@date-io/date-fns';
import { format, addDays, differenceInDays } from 'date-fns';
import { es } from 'date-fns/locale';

import { GuveryTextField } from '#root/components';
import { QuestionMarkCircleIcon, ArrowDownwardIcon } from '#root/icons';
import styles from './extraData.module.scss';

const expirationOptions = [
  { value: 0, label: 'Hoy', color: '--color-orange' },
  { value: 1, label: 'Mañana', color: '--color-warning' },
  { value: 2, label: 'En 2 días', color: '--color-purple' },
  { value: 3, label: 'En 3 días', color: '--color-purple' },
];

const getExpirationDaysLabel = (days: number): string => {
  return expirationOptions.find((option) => option.value === days).label;
};

const getExpirationDaysColor = (days: number): string => {
  return expirationOptions.find((option) => option.value === days).color;
};

const ExtraData = (props) => {
  const { offer, flagEnvio, handleDateChange, handleInputChange, loading } = props;
  const [expanded, setExpanded] = useState(false);
  const [expirationDays, setExpirationDays] = useState(2);

  const dateDelivery = offer?.delivery_date
    ? format(new Date(offer.delivery_date), "dd'/'MMM", {
        locale: es,
      })
    : '---';

  const daysToExpirationDate = (days: number) => {
    return addDays(new Date(), days);
  };

  const handleExpirationChange = (event) => {
    const daysToAdd = event.target.value;
    handleDateChange('expiration_date')(daysToExpirationDate(daysToAdd));
    setExpirationDays(daysToAdd);
  };

  const form = (
    <Grid container spacing={3} className={styles.form}>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Mi oferta vence</InputLabel>
          <Select
            value={expirationDays}
            onChange={handleExpirationChange}
            label="Mi oferta vence">
            {expirationOptions.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                style={{ color: `var(${option.color})` }}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <KeyboardDatePicker
            disableToolbar
            fullWidth
            label="Fecha de entrega"
            inputVariant="outlined"
            format="dd/MM/yyyy"
            value={offer.delivery_date}
            onChange={handleDateChange('delivery_date')}
            minDate={new Date()}
            InputAdornmentProps={{ position: 'end' }}
            minDateMessage="La fecha no debe ser anterior a la fecha mínima"
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12}>
        {loading ? (
          <Skeleton variant="rect" width="100%" height={56} />
        ) : (
          <GuveryTextField
            id="delivery_places"
            disabled={flagEnvio}
            label="Lugares de entrega"
            value={offer.delivery_places}
            onChange={handleInputChange}
          />
        )}
        {flagEnvio && (
          <Tooltip
            arrow
            title="Guvery le enviará este pedido al comprador, porque se encuentra fuera de Lima. "
            placement="bottom">
            <div className={styles.formHelp}>
              <p>
                Debe entregarse en la oficina de MailBoxes (Miraflores, San Isidro ó Surco).
              </p>
              <QuestionMarkCircleIcon />
            </div>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );

  const Text = (
    <div className={styles.header}>
      {expanded ? (
        <h5>
          <strong>Datos de entrega</strong>
        </h5>
      ) : (
        <h5>
          Entregando el <strong>{dateDelivery}</strong> en [{' '}
          <strong>{offer.delivery_places}</strong> ]. Mi oferta vence{' '}
          <strong style={{ color: `var(${getExpirationDaysColor(expirationDays)})` }}>
            {getExpirationDaysLabel(expirationDays)}
          </strong>
          .
        </h5>
      )}
    </div>
  );

  useEffect(() => {
    if (offer.expiration_date) {
      const days = differenceInDays(new Date(offer.expiration_date), new Date());
      if (days < 2) {
        setExpirationDays(1);
        handleDateChange('expiration_date')(daysToExpirationDate(1));
      } else if (days < 3) {
        setExpirationDays(2);
        handleDateChange('expiration_date')(daysToExpirationDate(2));
      } else {
        setExpirationDays(3);
        handleDateChange('expiration_date')(daysToExpirationDate(3));
      }
    }
  }, []);

  return (
    <div className={styles.root}>
      <Accordion
        className={styles.acordion}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          expandIcon={<ArrowDownwardIcon />}
          IconButtonProps={{ size: 'small' }}>
          {Text}
        </AccordionSummary>
        <AccordionDetails>{form}</AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ExtraData;
